import 'react-datepicker/dist/react-datepicker.css';

import { useNavigate, useSearchParams } from '@remix-run/react';
import { format, startOfDay, subDays } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import { $path } from 'remix-routes';
import useSWR from 'swr';

import {
  type DtoCourseAnalytics,
  type DtoGetLearningAnalyticsResponse,
  type DtoLearnerAnalytics,
} from '@lp-lib/api-service-client/public';

import placeholder from '../../../../assets/img/placeholder/game-cover.png';
import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { getLogger } from '../../../../logger/logger';
import { apiService } from '../../../../services/api-service';
import { fromMediaDTO } from '../../../../utils/api-dto';
import { MediaUtils } from '../../../../utils/media';
import { SegmentedControl } from '../../../common/SegmentedControl';
import { type Option } from '../../../common/Utilities';
import { useAwaitFullScreenConfirmCancelModal } from '../../../ConfirmCancelModalContext';
import { ModalWrapper } from '../../../ConfirmCancelModalContext/ModalWrapper';
import { ArrowRightIcon } from '../../../icons/ArrowRightIcon';
import { AssessmentIcon } from '../../../icons/AssessmentIcon';
import { OptimizedCheckedIcon } from '../../../icons/CheckedIcon';
import { FilterIcon } from '../../../icons/FilterIcon';
import { StackedSquaresIcon } from '../../../icons/StackedSquaresIcon';
import { XMarkIcon } from '../../../icons/XIcon';
import { assessmentScoreToColor } from '../assessmentScoreToColor';
import {
  EnrollableMultiSelect,
  type EnrollableOption,
} from '../Components/EnrollableMultiSelect';
import {
  LearningGroupMultiSelect,
  type LearningGroupOption,
} from '../Components/LearningGroupMultiSelect';
import { SortIcon } from './SortIcon';

const logger = getLogger().scoped('learning-analytics');

type SortField =
  | 'name'
  | 'coursesCompleted'
  | 'avgAssessmentScore'
  | 'courseName'
  | 'learnersCount'
  | 'completedCount';

type SortDirection = 'asc' | 'desc' | null;

interface LearningAnalyticsSortState {
  sortField: SortField | null;
  sortDirection: SortDirection;
}

interface FilterFormData {
  startDate: Date | null;
  endDate: Date | null;
  groupIds: LearningGroupOption[];
  courseIds: EnrollableOption[];
}

function useTriggerAnalyticsFilterModal() {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  return useCallback(
    (props: {
      initialValues: {
        startDate: Date | null;
        endDate: Date | null;
        groupIds: string[];
        courseIds: string[];
      };
      onApply: (data: FilterFormData) => void;
    }) => {
      return triggerModal({
        kind: 'custom',
        containerClassName: 'bg-lp-black-002',
        element: (p) => (
          <AnalyticsFilterModal
            initialValues={props.initialValues}
            onClose={p.internalOnCancel}
            onApply={(data) => {
              props.onApply(data);
              p.internalOnConfirm();
            }}
          />
        ),
      });
    },
    [triggerModal]
  );
}

function AnalyticsFilterModal({
  onClose,
  onApply,
  initialValues,
}: {
  onClose: () => void;
  onApply: (data: FilterFormData) => void;
  initialValues: {
    startDate: Date | null;
    endDate: Date | null;
    groupIds: string[];
    courseIds: string[];
  };
}) {
  const { data: initialGroupOptions } = useSWR(
    initialValues.groupIds.length > 0
      ? ['initial-groups', initialValues.groupIds]
      : null,
    async () => {
      try {
        const response = await apiService.learning.getOrgGroupsByIds(
          initialValues.groupIds
        );
        const groups = response.data.groups || [];

        return groups.map((group) => ({
          kind: 'existing' as const,
          group,
          value: group.id,
        }));
      } catch (error) {
        logger.error('Failed to load initial groups', error);
        return [];
      }
    },
    {
      revalidateOnFocus: false,
    }
  );

  const { data: initialCourseOptions } = useSWR(
    initialValues.courseIds.length > 0
      ? ['initial-courses', initialValues.courseIds]
      : null,
    async () => {
      try {
        const response = await apiService.learning.getEnrollablesByIds(
          initialValues.courseIds
        );
        const enrollables = response.data.enrollables || [];

        return enrollables.map((enrollable) => ({
          kind: 'existing' as const,
          enrollable,
          value: enrollable.objectId,
        }));
      } catch (error) {
        logger.error('Failed to load initial enrollables', error);
        return [];
      }
    },
    {
      revalidateOnFocus: false,
    }
  );

  const { control, handleSubmit, setValue, watch } = useForm<FilterFormData>({
    defaultValues: {
      startDate: initialValues.startDate,
      endDate: initialValues.endDate,
      groupIds: [],
      courseIds: [],
    },
  });

  useEffect(() => {
    if (initialGroupOptions) {
      setValue('groupIds', initialGroupOptions);
    }
  }, [initialGroupOptions, setValue]);

  useEffect(() => {
    if (initialCourseOptions) {
      setValue('courseIds', initialCourseOptions);
    }
  }, [initialCourseOptions, setValue]);

  const startDate = watch('startDate');
  const endDate = watch('endDate');

  const handleSetLast7Days = () => {
    const end = new Date();
    const start = subDays(end, 7);
    setValue('startDate', startOfDay(start));
    setValue('endDate', end);
  };

  const handleSetLast30Days = () => {
    const end = new Date();
    const start = subDays(end, 30);
    setValue('startDate', startOfDay(start));
    setValue('endDate', end);
  };

  const handleSetAllTime = () => {
    setValue('startDate', null);
    setValue('endDate', null);
  };

  const onSubmit = handleSubmit((data) => {
    onApply(data);
  });

  return (
    <ModalWrapper
      borderStyle='gray'
      containerClassName='w-[600px]'
      innerClassName='bg-main-layer flex flex-col'
      onClose={onClose}
    >
      <form className='w-full px-8 py-7.5 text-white' onSubmit={onSubmit}>
        <header className='text-2xl font-bold mb-8 text-center'>
          Filter Options
        </header>

        <main className='flex flex-col gap-7.5'>
          <div className='flex flex-col gap-4'>
            <div className='flex justify-between items-center'>
              <h3 className='text-base font-bold'>Date Range</h3>
              <div className='flex gap-2'>
                <button
                  type='button'
                  onClick={handleSetLast7Days}
                  className='btn-secondary text-xs px-2 py-1'
                >
                  Last 7 Days
                </button>
                <button
                  type='button'
                  onClick={handleSetLast30Days}
                  className='btn-secondary text-xs px-2 py-1'
                >
                  Last 30 Days
                </button>
                <button
                  type='button'
                  onClick={handleSetAllTime}
                  className='btn-secondary text-xs px-2 py-1'
                >
                  All Time
                </button>
              </div>
            </div>

            <div className='grid grid-cols-2 gap-4'>
              <div>
                <label className='text-sm text-icon-gray mb-1 block'>
                  Start Date
                </label>
                <Controller
                  control={control}
                  name='startDate'
                  render={({ field }) => (
                    <ReactDatePicker
                      className='field h-10 mb-0 w-full'
                      selected={field.value}
                      onChange={field.onChange}
                      selectsStart
                      startDate={field.value}
                      endDate={endDate}
                      placeholderText='Select start date'
                      dateFormat='MMM d, yyyy'
                      maxDate={endDate ?? undefined}
                      popperPlacement='bottom-start'
                      popperClassName='date-picker-popper'
                    />
                  )}
                />
              </div>
              <div>
                <label className='text-sm text-icon-gray mb-1 block'>
                  End Date
                </label>
                <Controller
                  control={control}
                  name='endDate'
                  render={({ field }) => (
                    <ReactDatePicker
                      className='field h-10 mb-0 w-full'
                      selected={field.value}
                      onChange={field.onChange}
                      selectsEnd
                      startDate={startDate}
                      endDate={field.value}
                      minDate={startDate}
                      placeholderText='Select end date'
                      dateFormat='MMM d, yyyy'
                      popperPlacement='bottom-start'
                      popperClassName='date-picker-popper'
                    />
                  )}
                />
              </div>
            </div>
          </div>

          <div className='flex flex-col gap-2'>
            <h3 className='text-base font-bold mb-1'>Filter by Groups</h3>
            <Controller
              control={control}
              name='groupIds'
              render={({ field }) => (
                <LearningGroupMultiSelect
                  options={field.value}
                  onChange={field.onChange}
                  placeholder='Select groups to filter by'
                />
              )}
            />
          </div>

          <div className='flex flex-col gap-2'>
            <h3 className='text-base font-bold mb-1'>
              Filter by Courses/Stacks
            </h3>
            <Controller
              control={control}
              name='courseIds'
              render={({ field }) => (
                <EnrollableMultiSelect
                  options={field.value}
                  onChange={field.onChange}
                  placeholder='Select Courses and Stacks'
                />
              )}
            />
          </div>
        </main>

        <footer className='mt-10'>
          <div className='flex justify-center gap-4'>
            <button
              type='button'
              onClick={onClose}
              className='btn-secondary w-40 h-10'
            >
              Cancel
            </button>
            <button type='submit' className='btn-primary w-40 h-10'>
              Apply Filters
            </button>
          </div>
        </footer>
      </form>
    </ModalWrapper>
  );
}

function useLearningAnalyticsFilters() {
  const [searchParams, setSearchParams] = useSearchParams();

  const sortState = useMemo((): LearningAnalyticsSortState => {
    const sortField = searchParams.get('sort') as SortField | null;
    const rawDir = searchParams.get('dir');
    const sortDirection: SortDirection =
      rawDir === 'desc' ? 'desc' : rawDir === 'asc' ? 'asc' : null;

    return { sortField, sortDirection };
  }, [searchParams]);

  const filterState = useMemo(() => {
    const startDate = searchParams.get('startDate');
    const endDate = searchParams.get('endDate');
    const groupIds = searchParams.get('groupIds');
    const courseIds = searchParams.get('courseIds');

    return {
      startDate: startDate ? new Date(startDate) : null,
      endDate: endDate ? new Date(endDate) : null,
      groupIds: groupIds ? groupIds.split(',') : [],
      courseIds: courseIds ? courseIds.split(',') : [],
    };
  }, [searchParams]);

  const toggleSort = useCallback(
    (field: SortField) => {
      let newDirection: SortDirection = 'asc';

      if (sortState.sortField === field) {
        if (sortState.sortDirection === 'asc') {
          newDirection = 'desc';
        } else if (sortState.sortDirection === 'desc') {
          newDirection = null;
        }
      }

      setSearchParams(
        (params) => {
          const newParams = new URLSearchParams(params);

          if (newDirection === null) {
            newParams.delete('sort');
            newParams.delete('dir');
          } else {
            newParams.set('sort', field);
            newParams.set('dir', newDirection);
          }

          return newParams;
        },
        { replace: true }
      );
    },
    [setSearchParams, sortState]
  );

  const setTab = useCallback(
    (tab: string) => {
      setSearchParams(
        (params) => {
          const newParams = new URLSearchParams(params);
          newParams.set('tab', tab);
          return newParams;
        },
        { replace: true }
      );
    },
    [setSearchParams]
  );

  const setFilters = useCallback(
    (filters: {
      startDate: Date | null;
      endDate: Date | null;
      groupIds: LearningGroupOption[];
      courseIds: EnrollableOption[];
    }) => {
      setSearchParams(
        (params) => {
          const newParams = new URLSearchParams(params);

          if (filters.startDate) {
            newParams.set('startDate', filters.startDate.toISOString());
          } else {
            newParams.delete('startDate');
          }

          if (filters.endDate) {
            newParams.set('endDate', filters.endDate.toISOString());
          } else {
            newParams.delete('endDate');
          }

          if (filters.groupIds.length > 0) {
            const groupIdValues = filters.groupIds.map((g) => g.value);
            newParams.set('groupIds', groupIdValues.join(','));
          } else {
            newParams.delete('groupIds');
          }

          if (filters.courseIds.length > 0) {
            const courseIdValues = filters.courseIds.map((c) => c.value);
            newParams.set('courseIds', courseIdValues.join(','));
          } else {
            newParams.delete('courseIds');
          }

          return newParams;
        },
        { replace: true }
      );
    },
    [setSearchParams]
  );

  const removeStartDate = useCallback(() => {
    setSearchParams(
      (params) => {
        const newParams = new URLSearchParams(params);
        newParams.delete('startDate');
        return newParams;
      },
      { replace: true }
    );
  }, [setSearchParams]);

  const removeEndDate = useCallback(() => {
    setSearchParams(
      (params) => {
        const newParams = new URLSearchParams(params);
        newParams.delete('endDate');
        return newParams;
      },
      { replace: true }
    );
  }, [setSearchParams]);

  const removeGroup = useCallback(
    (groupId: string) => {
      setSearchParams(
        (params) => {
          const newParams = new URLSearchParams(params);
          const currentGroups = params.get('groupIds');

          if (currentGroups) {
            const groupIds = currentGroups.split(',');
            const filteredGroups = groupIds.filter((id) => id !== groupId);

            if (filteredGroups.length > 0) {
              newParams.set('groupIds', filteredGroups.join(','));
            } else {
              newParams.delete('groupIds');
            }
          }

          return newParams;
        },
        { replace: true }
      );
    },
    [setSearchParams]
  );

  const removeCourse = useCallback(
    (courseId: string) => {
      setSearchParams(
        (params) => {
          const newParams = new URLSearchParams(params);
          const currentCourses = params.get('courseIds');

          if (currentCourses) {
            const courseIds = currentCourses.split(',');
            const filteredCourses = courseIds.filter((id) => id !== courseId);

            if (filteredCourses.length > 0) {
              newParams.set('courseIds', filteredCourses.join(','));
            } else {
              newParams.delete('courseIds');
            }
          }

          return newParams;
        },
        { replace: true }
      );
    },
    [setSearchParams]
  );

  return {
    ...sortState,
    ...filterState,
    toggleSort,
    setTab,
    setFilters,
    removeStartDate,
    removeEndDate,
    removeGroup,
    removeCourse,
  };
}

function LearnerAnalyticsRowItem({ row }: { row: DtoLearnerAnalytics }) {
  const navigate = useNavigate();

  const handleClick = useLiveCallback(() => {
    navigate($path('/learning/admin/learners/:id/profile', { id: row.userId }));
  });

  const formatScore = (score: number | null | undefined) => {
    if (score === null || score === undefined) return 'N/A';
    return `${Math.round(score * 100)}%`;
  };

  return (
    <div
      className='w-full rounded-lg px-4 py-3 bg-[#17171A] flex items-center hover:bg-[#1f1f22] transition-colors relative border-lp-gray-003 border cursor-pointer'
      onClick={handleClick}
    >
      <div className='w-1/4 text-white font-medium text-sm truncate'>
        {row.name || row.email}
      </div>
      <div className='w-1/4 text-white/90 text-sm truncate'>
        {row.groups.length > 0 ? row.groups.join(', ') : 'N/A'}
      </div>

      <div className='w-1/4 text-white/90 text-sm flex items-center justify-center'>
        {`${row.coursesCompleted}/${row.totalCourses}`}
      </div>

      <div
        className={`w-1/4 text-sm flex items-center justify-center ${assessmentScoreToColor(
          row.avgAssessmentScore
        )}`}
      >
        {formatScore(row.avgAssessmentScore)}
      </div>

      <div className='ml-auto flex items-center justify-center'>
        <ArrowRightIcon className='w-4 h-4' />
      </div>
    </div>
  );
}

function CourseAnalyticsRowItem({ row }: { row: DtoCourseAnalytics }) {
  const navigate = useNavigate();

  const handleClick = useLiveCallback(() => {
    if (row.type === 'stack') {
      navigate(
        $path('/learning/admin/my-courses/stacks/:id', { id: row.courseId })
      );
    } else {
      navigate($path('/learning/admin/my-courses/:id', { id: row.courseId }));
    }
  });

  const formatScore = (score: number | null | undefined) => {
    if (score === null || score === undefined) return 'N/A';
    return `${Math.round(score * 100)}%`;
  };

  const coverSrc = row.cover
    ? MediaUtils.PickMediaUrl(fromMediaDTO(row.cover))
    : placeholder;

  return (
    <div
      className='w-full rounded-lg px-2 py-2 bg-[#17171A] flex items-center hover:bg-[#1f1f22] transition-colors relative border-lp-gray-003 border cursor-pointer'
      onClick={handleClick}
    >
      <div className='w-21 h-12 rounded-lg overflow-hidden mr-4 flex-shrink-0'>
        <img
          src={coverSrc ?? undefined}
          alt={row.courseName}
          className='w-full h-full object-cover'
        />
      </div>

      <div className='w-1/5 text-white font-medium text-sm truncate flex items-center'>
        <span className='truncate'>{row.courseName}</span>
        {row.type === 'stack' && (
          <span className='ml-2'>
            <StackedSquaresIcon className='w-4 h-4 fill-current text-gray-400' />
          </span>
        )}
      </div>

      <div className='w-1/5 text-white/90 text-sm flex items-center justify-center'>
        {row.learnersCount}
      </div>

      <div className='w-1/5 text-white/90 text-sm flex items-center justify-center'>
        {`${row.completedCount}/${row.learnersCount}`}
      </div>

      <div
        className={`w-1/5 text-sm flex items-center justify-center ${assessmentScoreToColor(
          row.avgAssessmentScore
        )}`}
      >
        {formatScore(row.avgAssessmentScore)}
      </div>

      <div className='ml-auto mr-2 flex items-center justify-center'>
        <ArrowRightIcon className='w-4 h-4' />
      </div>
    </div>
  );
}

type Tab = 'learners' | 'courses';

export interface LearningAnalyticsPageProps {
  initialTab: string;
  data?: DtoGetLearningAnalyticsResponse;
}

const tabOptions: Option<Tab>[] = [
  { label: 'Learners', value: 'learners' },
  { label: 'Courses', value: 'courses' },
];

export interface SortableHeaderProps {
  field: SortField;
  label: string;
  centerAlign?: boolean;
  sortField: SortField | null;
  sortDirection: SortDirection;
  toggleSort: (field: SortField) => void;
}

function SortableHeader({
  field,
  label,
  centerAlign = false,
  sortField,
  sortDirection,
  toggleSort,
}: SortableHeaderProps) {
  const direction = sortField === field ? sortDirection : null;

  return (
    <div
      className={`flex items-center gap-1 cursor-pointer select-none ${
        centerAlign ? 'justify-center w-full' : ''
      }`}
      onClick={() => toggleSort(field)}
    >
      {label}
      <button
        type='button'
        className='text-xs p-1 hover:bg-lp-gray-006 rounded'
      >
        <SortIcon direction={direction} className='w-3.5 h-3.5' />
      </button>
    </div>
  );
}

interface ActiveFiltersDisplayProps {
  startDate: Date | null;
  endDate: Date | null;
  groups: LearningGroupOption[];
  courses: EnrollableOption[];
  onRemoveStartDate: () => void;
  onRemoveEndDate: () => void;
  onRemoveGroup: (groupId: string) => void;
  onRemoveCourse: (courseId: string) => void;
}

interface EnhancedActiveFiltersDisplayProps extends ActiveFiltersDisplayProps {
  groupIds: string[];
  courseIds: string[];
}

function EnhancedActiveFiltersDisplay({
  startDate,
  endDate,
  groups,
  courses,
  groupIds,
  courseIds,
  onRemoveStartDate,
  onRemoveEndDate,
  onRemoveGroup,
  onRemoveCourse,
}: EnhancedActiveFiltersDisplayProps) {
  const hasFilters =
    startDate || endDate || groupIds.length > 0 || courseIds.length > 0;

  if (!hasFilters) {
    return null;
  }

  return (
    <div className='mb-4'>
      <div className='text-gray-400 text-xs uppercase font-medium mb-2'>
        Active Filters
      </div>
      <div className='flex flex-wrap gap-2'>
        {startDate && (
          <span className='bg-yellow-500 text-white text-xs px-3 py-1.5 rounded-full flex items-center gap-1.5'>
            <span>From: {format(startDate, 'MMM d, yyyy')}</span>
            <button
              type='button'
              onClick={onRemoveStartDate}
              className='hover:bg-black/20 rounded-full p-0.5'
            >
              <XMarkIcon className='w-3 h-3 text-white' />
            </button>
          </span>
        )}

        {endDate && (
          <span className='bg-yellow-500 text-white text-xs px-3 py-1.5 rounded-full flex items-center gap-1.5'>
            <span>Until: {format(endDate, 'MMM d, yyyy')}</span>
            <button
              type='button'
              onClick={onRemoveEndDate}
              className='hover:bg-black/20 rounded-full p-0.5'
            >
              <XMarkIcon className='w-3 h-3 text-white' />
            </button>
          </span>
        )}

        {groups.map((group) => (
          <span
            key={group.value}
            className='bg-[#01ACC4] text-white text-xs px-3 py-1.5 rounded-full flex items-center gap-1.5'
          >
            <span>{group.group.name}</span>
            <button
              type='button'
              onClick={() => onRemoveGroup(group.value)}
              className='hover:bg-black/20 rounded-full p-0.5'
            >
              <XMarkIcon className='w-3 h-3 text-white' />
            </button>
          </span>
        ))}

        {groupIds.length > groups.length && (
          <span className='bg-[#01ACC4] text-white text-xs px-3 py-1.5 rounded-full flex items-center gap-1.5 opacity-70'>
            <span>Loading {groupIds.length - groups.length} more...</span>
          </span>
        )}

        {courses.map((course) => (
          <span
            key={course.value}
            className='bg-[#8C6FFF] text-white text-xs px-3 py-1.5 rounded-full flex items-center gap-1.5'
          >
            <span>{course.enrollable.name}</span>
            <button
              type='button'
              onClick={() => onRemoveCourse(course.value)}
              className='hover:bg-black/20 rounded-full p-0.5'
            >
              <XMarkIcon className='w-3 h-3 text-white' />
            </button>
          </span>
        ))}

        {courseIds.length > courses.length && (
          <span className='bg-[#8C6FFF] text-white text-xs px-3 py-1.5 rounded-full flex items-center gap-1.5 opacity-70'>
            <span>Loading {courseIds.length - courses.length} more...</span>
          </span>
        )}
      </div>
    </div>
  );
}

export function LearningAnalyticsPage({
  initialTab,
  data,
}: LearningAnalyticsPageProps) {
  const currentTab = (initialTab === 'courses' ? 'courses' : 'learners') as Tab;
  const {
    sortField,
    sortDirection,
    startDate,
    endDate,
    groupIds,
    courseIds,
    toggleSort,
    setTab,
    setFilters,
    removeStartDate,
    removeEndDate,
    removeGroup,
    removeCourse,
  } = useLearningAnalyticsFilters();

  const triggerFilterModal = useTriggerAnalyticsFilterModal();

  const [filterCache, setFilterCache] = useState<{
    groups: Record<string, LearningGroupOption>;
    courses: Record<string, EnrollableOption>;
  }>({
    groups: {},
    courses: {},
  });

  const { data: fetchedGroupOptions = [] } = useSWR(
    groupIds.length > 0 ? ['learning-groups', groupIds] : null,
    async () => {
      try {
        const response = await apiService.learning.getOrgGroupsByIds(groupIds);
        const groups = response.data.groups || [];

        return groups.map((group) => ({
          kind: 'existing' as const,
          group,
          value: group.id,
        }));
      } catch (error) {
        logger.error('Failed to load active groups', error);
        return [];
      }
    },
    {
      revalidateOnFocus: false,
    }
  );

  const { data: fetchedCourseOptions = [] } = useSWR(
    courseIds.length > 0 ? ['learning-courses', courseIds] : null,
    async () => {
      try {
        const response = await apiService.learning.getEnrollablesByIds(
          courseIds
        );
        const enrollables = response.data.enrollables || [];

        return enrollables.map((enrollable) => ({
          kind: 'existing' as const,
          enrollable,
          value: enrollable.objectId,
        }));
      } catch (error) {
        logger.error('Failed to load active courses', error);
        return [];
      }
    },
    {
      revalidateOnFocus: false,
    }
  );

  useEffect(() => {
    if (fetchedGroupOptions.length > 0) {
      setFilterCache((prev) => {
        const newGroupsCache = { ...prev.groups };

        fetchedGroupOptions.forEach((group) => {
          newGroupsCache[group.value] = group;
        });

        return {
          ...prev,
          groups: newGroupsCache,
        };
      });
    }
  }, [fetchedGroupOptions]);

  useEffect(() => {
    if (fetchedCourseOptions.length > 0) {
      setFilterCache((prev) => {
        const newCoursesCache = { ...prev.courses };

        fetchedCourseOptions.forEach((course) => {
          newCoursesCache[course.value] = course;
        });

        return {
          ...prev,
          courses: newCoursesCache,
        };
      });
    }
  }, [fetchedCourseOptions]);

  const currentGroupOptions = useMemo(() => {
    if (groupIds.length === 0) return [];

    return groupIds.map((id) => filterCache.groups[id]).filter(Boolean);
  }, [groupIds, filterCache.groups]);

  const currentCourseOptions = useMemo(() => {
    if (courseIds.length === 0) return [];

    return courseIds.map((id) => filterCache.courses[id]).filter(Boolean);
  }, [courseIds, filterCache.courses]);

  const handleRemoveStartDate = useCallback(() => {
    removeStartDate();
  }, [removeStartDate]);

  const handleRemoveEndDate = useCallback(() => {
    removeEndDate();
  }, [removeEndDate]);

  const handleRemoveGroup = useCallback(
    (groupId: string) => {
      removeGroup(groupId);
    },
    [removeGroup]
  );

  const handleRemoveCourse = useCallback(
    (courseId: string) => {
      removeCourse(courseId);
    },
    [removeCourse]
  );

  const sortedLearners = data?.learnersData?.learners || [];
  const sortedCourses = data?.coursesData?.courses || [];

  const totalCoursesCompleted = data?.completedEnrollmentsCount || 0;
  const totalCourses = data?.totalEnrollmentsCount || 0;

  const overallAssessmentScore = data?.avgAssessmentScore;

  const formatScore = (score: number | null | undefined) => {
    if (score === null || score === undefined) return 'N/A';
    return `${Math.round(score * 100)}%`;
  };

  interface AnalyticsCardProps {
    title: string;
    icon: React.ReactNode;
    value: React.ReactNode;
    valueColor?: string;
  }

  const AnalyticsCard = ({
    title,
    icon,
    value,
    valueColor = 'text-white',
  }: AnalyticsCardProps) => (
    <div className='bg-black rounded-xl p-5 w-1/3 md:w-1/4 lg:w-1/5 flex flex-col h-36'>
      <div className='flex-grow min-h-[70px] flex items-start'>
        <div className='flex gap-3 text-icon-gray items-center'>
          <div className='flex-shrink-0'>{icon}</div>
          <span className='text-sm leading-tight'>{title}</span>
        </div>
      </div>

      <div className='mt-auto'>
        <div className={`text-2xl font-bold ${valueColor}`}>{value}</div>
      </div>
    </div>
  );

  const AverageAssessmentCard = () => {
    return (
      <AnalyticsCard
        title={'Total Assessment Average'}
        icon={<AssessmentIcon className='w-5 h-5 fill-current' />}
        value={formatScore(overallAssessmentScore)}
        valueColor={assessmentScoreToColor(overallAssessmentScore)}
      />
    );
  };

  const CoursesAssignedCard = () => (
    <AnalyticsCard
      title='Total Assignments'
      icon={<StackedSquaresIcon className='w-5 h-5 fill-current' />}
      value={totalCourses}
    />
  );

  const AssignmentsCompletedCard = () => (
    <AnalyticsCard
      title='Assignments Completed'
      icon={<OptimizedCheckedIcon className='w-5 h-5 fill-current' />}
      value={`${totalCoursesCompleted}`}
    />
  );

  return (
    <div className='w-full h-full text-white px-8 py-10'>
      <div className='max-w-screen-2xl mx-auto pb-6'>
        <h1 className='text-3xl font-bold mb-6'>Analytics Dashboard</h1>

        <div className='flex gap-6 mb-8'>
          <AverageAssessmentCard />
          <CoursesAssignedCard />
          <AssignmentsCompletedCard />
        </div>

        <div className='flex justify-between items-center mb-8'>
          <div className='flex gap-2'>
            <SegmentedControl
              options={tabOptions}
              value={tabOptions.find((o) => o.value === currentTab)}
              onChange={(selectedOption) => setTab(selectedOption.value)}
              styles={{
                glider: 'bg-lp-gray-006 rounded-lg',
                track: 'rounded-xl p-1 border border-secondary',
                option: 'px-4 py-2 transition-colors text-sms',
                selectedOption: 'text-white',
                unselectedOption: 'text-gray-400 hover:text-white',
              }}
            />
          </div>

          <button
            type='button'
            className='btn-secondary h-10 flex items-center gap-2 px-4'
            onClick={() => {
              triggerFilterModal({
                initialValues: {
                  startDate,
                  endDate,
                  groupIds,
                  courseIds,
                },
                onApply: (data) => {
                  setFilters(data);
                },
              });
            }}
          >
            <FilterIcon className='w-4 h-4 fill-current text-white' />
            Filter
            {(startDate ||
              endDate ||
              groupIds.length > 0 ||
              courseIds.length > 0) && (
              <span className='bg-white text-black rounded-full w-5 h-5 flex items-center justify-center text-xs ml-1'>
                {(startDate ? 1 : 0) +
                  (endDate ? 1 : 0) +
                  (groupIds.length > 0 ? 1 : 0) +
                  (courseIds.length > 0 ? 1 : 0)}
              </span>
            )}
          </button>
        </div>

        <EnhancedActiveFiltersDisplay
          startDate={startDate}
          endDate={endDate}
          groups={currentGroupOptions}
          courses={currentCourseOptions}
          groupIds={groupIds}
          courseIds={courseIds}
          onRemoveStartDate={handleRemoveStartDate}
          onRemoveEndDate={handleRemoveEndDate}
          onRemoveGroup={handleRemoveGroup}
          onRemoveCourse={handleRemoveCourse}
        />

        {currentTab === 'learners' && (
          <>
            <div className='w-full px-4 py-2 flex items-center text-gray-400 text-xs uppercase font-medium'>
              <div className='w-1/4'>
                <SortableHeader
                  field='name'
                  label='Name'
                  sortField={sortField}
                  sortDirection={sortDirection}
                  toggleSort={toggleSort}
                />
              </div>
              <div className='w-1/4'>Groups</div>
              <div className='w-1/4'>
                <SortableHeader
                  field='coursesCompleted'
                  label='Assignments Completed'
                  centerAlign={true}
                  sortField={sortField}
                  sortDirection={sortDirection}
                  toggleSort={toggleSort}
                />
              </div>
              <div className='w-1/4'>
                <SortableHeader
                  field='avgAssessmentScore'
                  label='Avg Score'
                  centerAlign={true}
                  sortField={sortField}
                  sortDirection={sortDirection}
                  toggleSort={toggleSort}
                />
              </div>
              <div className='ml-auto'></div>
            </div>

            <div className='flex flex-col gap-2.5 mt-2'>
              {sortedLearners.length > 0 ? (
                sortedLearners.map((row) => (
                  <LearnerAnalyticsRowItem key={row.userId} row={row} />
                ))
              ) : (
                <div className='w-full py-8 text-center text-gray-400'>
                  No learners data found
                </div>
              )}
            </div>
          </>
        )}

        {currentTab === 'courses' && (
          <>
            <div className='w-full px-4 py-2 flex items-center text-gray-400 text-xs uppercase font-medium'>
              <div className='w-1/5'>
                <SortableHeader
                  field='courseName'
                  label='Name'
                  sortField={sortField}
                  sortDirection={sortDirection}
                  toggleSort={toggleSort}
                />
              </div>
              <div className='w-21 mr-4'></div>
              <div className='w-1/5'>
                <SortableHeader
                  field='learnersCount'
                  label='Learners'
                  centerAlign={true}
                  sortField={sortField}
                  sortDirection={sortDirection}
                  toggleSort={toggleSort}
                />
              </div>
              <div className='w-1/5'>
                <SortableHeader
                  field='completedCount'
                  label='Assignments Completed'
                  centerAlign={true}
                  sortField={sortField}
                  sortDirection={sortDirection}
                  toggleSort={toggleSort}
                />
              </div>
              <div className='w-1/5'>
                <SortableHeader
                  field='avgAssessmentScore'
                  label='Avg Score'
                  centerAlign={true}
                  sortField={sortField}
                  sortDirection={sortDirection}
                  toggleSort={toggleSort}
                />
              </div>
              <div className='ml-auto'></div>
            </div>

            <div className='flex flex-col gap-2.5 mt-2'>
              {sortedCourses.length > 0 ? (
                sortedCourses.map((row) => (
                  <CourseAnalyticsRowItem key={row.courseId} row={row} />
                ))
              ) : (
                <div className='w-full py-8 text-center text-gray-400'>
                  No courses data found
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
